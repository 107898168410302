.training-records {
  margin-top: 20px;
}
.training-records .top-breadcrumb-wrapper {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 14px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.training-records .top-breadcrumb-wrapper .breadcrumb-title {
      color: #606266;
}
.training-records .top-breadcrumb-wrapper .breadcrumb-content {
      margin-left: 20px;
      cursor: pointer;
}
.training-records .top-breadcrumb-wrapper .breadcrumb-content .breadcrumb-before {
        color: #303133;
        font-weight: bold;
}
.meeting-container {
  padding: 20px 30px;
}
